import React,{useEffect} from 'react'
import Footer from '../Components/Footer'
import Gynae from '../Img/urology.jpg'

const Urologist = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>
    <br />
    <center><h1>Urology</h1></center>

    <div className='gyne'>

        <div className='gyne2'>
            <p><b>Bladder Issues: </b> Urologists diagnose and treat conditions such as urinary incontinence, overactive bladder, and interstitial cystitis.</p>
            <p><b>Kidney Conditions:</b> They manage kidney stones, kidney infections, and other kidney-related problems.</p>
            <p><b>Prostate Conditions: </b> Urologists address conditions such as benign prostatic hyperplasia (BPH) and prostate cancer.</p>
            <p><b>Stone Disease</b>Urologists are involved in the diagnosis and treatment of kidney stones and other urinary tract stones.</p>
            <p><b>Pediatric Urology: </b>Some urologists specialize in pediatric urology, addressing urinary and genital issues in children.</p>

        </div>


        <div className='gyne2'>
            <img src={Gynae} />
        </div>

    </div>

    <Footer />

</>
  )
}

export default Urologist