import React , {useEffect}from 'react'
import Footer from '../Components/Footer'
import Gynae from '../Img/lapro.jpg'

const Lapro = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <br />
            <center><h1>Laparoscopic Surgery</h1></center>

            <div className='gyne'>

                <div className='gyne2'>
                    <p><b>Laparoscopic Hysterectomy: </b> Removal of the uterus using laparoscopic techniques.</p>
                    <p><b>Laparoscopic Ovarian Cystectomy:</b> Removal of ovarian cysts.</p>
                    <p><b>Procedure Overview:</b> In laparoscopic surgery, a small camera and thin instruments are inserted through small incisions in the abdominal wall. The camera, called a laparoscope, provides a high-definition view of the internal organs on a monitor, allowing the surgeon to perform the surgery with precision.</p>
                    <p><b>Anesthesia:</b>Laparoscopic surgery is typically performed under general anesthesia, meaning the patient is unconscious during the procedure.</p>
                    <p><b>Advancements in Technology: </b>Continuous advancements in laparoscopic technology, such as improved cameras, instruments, and robotics, contribute to increased precision and expanded capabilities in performing complex surgeries.</p>

                </div>


                <div className='gyne2'>
                    <img src={Gynae} />
                </div>

            </div>

            <Footer />

        </>

    )
}

export default Lapro