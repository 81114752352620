import React, { useState,useEffect } from 'react';
import Footer from '../Components/Footer';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const Contact = () => {

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  // State variables to store form data
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [gender, setGender] = useState('');
  const [service, setService] = useState('');
  const [age, setAge] = useState('');

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create WhatsApp message
    const message = `*Contact Form Submission*\n\n` +
      `*Name:* ${name}\n` +
      `*Email:* ${email}\n` +
      `*Address:* ${address}\n` +
      `*Contact:* ${contact}\n` +
      `*Gender:* ${gender}\n` +
      `*Service:* ${service}\n` +
      `*Age:* ${age}`;

    // Encode message for URL
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/919140262702?text=${encodedMessage}`;

    // Redirect to WhatsApp
    window.location.href = whatsappUrl;
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          {/* Google Maps Section */}
          <div className="col-md-6 mb-4">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.0322367432764!2d80.938339!3d26.902472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39995634339bea9f%3A0xec519161d5ab8b75!2sUrmila%20Hospital!5e0!3m2!1sen!2sin!4v1708614165074!5m2!1sen!2sin"
              style={{ width: '100%', height: '100%', border: '0' }} 
              allowFullScreen="" 
              loading="lazy">
            </iframe>
          </div>

          {/* Contact Form Section */}
          <div className="col-md-6">
            <div className="card p-4 shadow-lg">
              <h2 className="text-center mb-4">Contact Form</h2>
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Enter your name" 
                      value={name} 
                      onChange={(e) => setName(e.target.value)} 
                      required 
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                      type="email" 
                      placeholder="Enter email" 
                      value={email} 
                      onChange={(e) => setEmail(e.target.value)} 
                      required 
                    />
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="formGridAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Enter address" 
                    value={address} 
                    onChange={(e) => setAddress(e.target.value)} 
                    required 
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridContact">
                  <Form.Label>Contact</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Enter contact number" 
                    value={contact} 
                    onChange={(e) => setContact(e.target.value)} 
                    required 
                  />
                </Form.Group>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridGender">
                    <Form.Label>Gender</Form.Label>
                    <Form.Select 
                      value={gender} 
                      onChange={(e) => setGender(e.target.value)} 
                      required
                    >
                      <option value="">Choose...</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridService">
                    <Form.Label>Service</Form.Label>
                    <Form.Select 
                      value={service} 
                      onChange={(e) => setService(e.target.value)} 
                      required
                    >
                      <option value="">Choose...</option>
                      <option value="General Checkup">General Checkup</option>
                      <option value="Dental Care">Dental Care</option>
                      <option value="Eye Specialist">Eye Specialist</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAge">
                    <Form.Label>Age</Form.Label>
                    <Form.Control 
                      type="number" 
                      placeholder="Enter age" 
                      value={age} 
                      onChange={(e) => setAge(e.target.value)} 
                      required 
                    />
                  </Form.Group>
                </Row>

                <div className="text-center">
                  <Button variant="primary" type="submit" className="w-100">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
