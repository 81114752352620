import React, {useEffect} from 'react'
import Footer from '../Components/Footer'

const Emergncy = () => {
      useEffect(() => {
              window.scrollTo(0, 0);
          }, []);
    return (
        <>
            <div className='emergency'>
                <h1>Emergency  24*7</h1><br />
                <p><b>Emergency Medical Services (EMS):</b> Ambulance services and emergency medical care are available 24/7 to respond to medical emergencies.</p>

                <p><b>Hospitals and Emergency Rooms:</b> Medical facilities, especially emergency rooms, are open 24/7 to handle medical emergencies.</p>
                <p><b>Follow-up Care:</b> After initial stabilization and treatment, patients may be admitted to the hospital for further care or discharged with instructions for follow-up care.</p>

            </div>
            <Footer />

        </>

    )
}

export default Emergncy