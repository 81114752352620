import React,{useEffect} from 'react'
import Footer from '../Components/Footer'
import Gynae from '../Img/neuro.jpg'

const Neuro = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <br />
            <center><h1>Neurosurgery</h1></center>

            <div className='gyne'>

                <div className='gyne2'>
                    <p><b>Brain Surgery (Cranial Neurosurgery): </b>Purpose: Primarily focuses on correcting defects or abnormalities resulting from congenital conditions, trauma, tumors, infections, or developmental issues.</p>
                    <p><b>Spinal Surgery (Spine Neurosurgery)</b> Focuses on disorders of the spine, including herniated discs, spinal cord compression, spinal tumors, and deformities like scoliosis.</p>
                    <p><b>Peripheral Nerve Surgery</b>  Addresses conditions affecting nerves outside the brain and spinal cord, such as carpal tunnel syndrome, peripheral nerve injuries, and nerve entrapments.</p>
                    <p><b>Functional Neurosurgery</b> Involves procedures to treat disorders like epilepsy, movement disorders (e.g., Parkinson's disease), and chronic pain through interventions such as deep brain stimulation (DBS) and neuroablation.</p>
                    <p><b>Vascular Neurosurgery:   </b> Specializes in treating conditions affecting blood vessels in the brain, such as aneurysms, arteriovenous malformations (AVMs), and strokes.</p>

                </div>

                <div className='gyne2'>
                    <img src={Gynae} />
                </div>

            </div>

            <Footer />

        </>
    )
}

export default Neuro