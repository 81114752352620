import React, {useEffect} from 'react'

import Footer from '../Components/Footer'
import Gynae from '../Img/General Medicine.png'

const Generalmedicine = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>
    <br />
    <center><h1>General  Medicine</h1></center>

    <div className='gyne'>

        <div className='gyne2'>
            <p><b>Primary Care: </b>  Internists often act as primary care physicians, providing comprehensive healthcare for adults. This involves routine check-ups, preventive care, and the management of common medical conditions.</p>
            <p><b>Diagnosis and Treatment:</b> Internists are skilled in diagnosing and treating a broad spectrum of medical conditions, including infectious diseases, metabolic disorders, cardiovascular diseases, respiratory conditions, gastrointestinal issues, and more.</p>
            <p><b>Preventive Medicine:</b>  General medicine emphasizes preventive care, including vaccinations, screenings, and lifestyle counseling to help patients maintain good health and prevent the onset of diseases.</p>
           

        </div>

        <div className='gyne2'>
            <img src={Gynae} />
        </div>

    </div>

    <Footer />

</>
  )
}

export default Generalmedicine