import React, {useEffect} from 'react'
import Ankus from '../Components/Ankus'
import Footer from '../Components/Footer'

const Manager = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
   <>
   
   <Ankus/>
   <Footer/>
   </>
  )
}

export default Manager