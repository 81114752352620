
import Ankus from '../Components/Ankus'
import Footer from '../Components/Footer'
import Abouts from '../Components/Abouts'

import React, {  useEffect } from 'react';

const About = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <>
 
   <Abouts/>
    <Footer/>
    
    </>
  )
}

export default About