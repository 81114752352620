import React , {useEffect}from 'react'

import Footer from '../Components/Footer'
import Gynae from '../Img/ent.jpg'

const Ent = () => {
      useEffect(() => {
              window.scrollTo(0, 0);
          }, []);
    return (
        <>
            <br />
            <center><h1>E.N.T.</h1></center>

            <div className='gyne'>

                <div className='gyne2'>
                    <p><b>Otology</b> Focuses on the ears and hearing. Otolaryngologists may diagnose and treat conditions such as hearing loss, ear infections, balance disorders, and ear-related facial nerve disorders.</p>
                    <p><b>Rhinology:</b> Concentrates on the nose and nasal passages. This involves the diagnosis and management of conditions like sinusitis, nasal polyps, nasal deformities, and allergies.</p>
                    <p><b>Laryngology</b> Deals with the throat and voice box (larynx). Otolaryngologists in this field address voice disorders, throat infections, vocal cord disorders, and swallowing issues.</p>

                    <p><b>Head and Neck Surgery</b>  Involves the surgical treatment of tumors, cancers, and other abnormalities in the head and neck region, including the thyroid and parathyroid glands.</p>
                    <p><b>Allergy Testing and Treatment:</b> Otolaryngologists often address allergies affecting the ears, nose, and throat, providing testing and treatment options.</p>

                </div>

                <div className='gyne2'>
                    <img src={Gynae} />
                </div>

            </div>
            <Footer />
        </>
    )
}

export default Ent