import React,{useEffect} from 'react'
import Footer from '../Components/Footer'
import Gynae from '../Img/gastro.jpg'

const Gastro = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <br />
            <center><h1>Gastro</h1></center>

            <div className='gyne'>

                <div className='gyne2'>
                    <p><b>Gastrointestinal Conditions: </b>  Gastroenterologists diagnose and treat a wide range of gastrointestinal disorders, including gastroesophageal reflux disease (GERD), inflammatory bowel diseases (such as Crohn's disease and ulcerative colitis), irritable bowel syndrome (IBS), and celiac disease.</p>
                    <p><b>Endoscopy Procedures:</b> Gastroenterologists often perform endoscopic procedures, such as esophagogastroduodenoscopy (EGD) and colonoscopy, to visualize and examine the GI tract. These procedures can aid in both diagnosis and treatment</p>
                    <p><b>Liver Diseases</b>  Gastroenterologists manage liver diseases, including viral hepatitis, cirrhosis, fatty liver disease, and liver tumors. They may work closely with hepatologists, who specialize specifically in liver disorders.</p>


                </div>

                <div className='gyne2'>
                    <img src={Gynae} />
                </div>

            </div>

            <Footer />

        </>
    )
}

export default Gastro