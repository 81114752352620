import React, { useState , useEffect } from 'react';

import Footer from '../Components/Footer';

const Appoint = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  // State variables for form inputs
  const [patientName, setPatientName] = useState('');
  const [address, setAddress] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Create WhatsApp message
    const message = `*Doctor Appointment Request*\n\n` +
      `*Patient Name:* ${patientName}\n` +
      `*Address:* ${address}\n` +
      `*Appointment Date:* ${appointmentDate}`;

    // Encode message for URL
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/919140262702?text=${encodedMessage}`;

    // Redirect to WhatsApp
    window.location.href = whatsappUrl;
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card shadow-lg p-4">
              <h2 className="text-center mb-4">Doctor Appointment Form</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                  <label className="form-label">Patient Name:</label>
                  <input 
                    type="text" 
                    className="form-control"
                    placeholder="Enter patient name" 
                    value={patientName} 
                    onChange={(e) => setPatientName(e.target.value)} 
                    required 
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Address:</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Enter address" 
                    value={address} 
                    onChange={(e) => setAddress(e.target.value)} 
                    required 
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Appointment Date:</label>
                  <input 
                    type="date" 
                    className="form-control" 
                    value={appointmentDate} 
                    onChange={(e) => setAppointmentDate(e.target.value)} 
                    required 
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary w-100">Submit Appointment</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Appoint;
