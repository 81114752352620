import React from 'react'

import doctor from '../Img/gallery.jpg';

const Ankus = () => {
    return (
        <>

            <br /><br />
            <center><h1>"Our Goal is more than saving your life"</h1></center>
            <div className='ankus'>
                <div className='ankus2'>
                    <h1>Why choose Urmila-Hospital?</h1>
                    <p>The Urmila-Hospital is one of the most advanced multi-specialty
                        enough beded hospital in the "Multi of city". The hospital has
                        experienced rapid growth to become a key player in the healthcare
                        sector of this city.</p>
                    <a href='Tel:9455020306'><button>CALL NOW  </button></a>
                    <a href='https://api.whatsapp.com/send?phone=9455020306' target='_blank'><button>WHATSAPP</button></a>

                </div>

                <div className='ankus2'>
                    <img src={doctor} />
                </div>
            </div>



<center><h1>Our Doctor</h1></center>

            <div className='doctor'>

                <div className='doctor2'>
                    <br /><br />
                    <h1>Gynaecology   </h1>
                </div>

                <div className='doctor2'>
                    <p> Dr. Meeta Mishra, DGO </p>
                    <p>Dr. Manjari Pandey, MD</p>
                </div>

            </div>

            


            <div className='doctor'>
                <div className='doctor2'>
                    <br /><br />
                    <h1>Laparoscopic Surgery  </h1>
                </div>
                <div className='doctor2'>
                    <p> Dr. Ankush Misra, MS, FIAGES, FALS </p>
                    <p>Dr. KK Agarwal, MS</p>
                </div>
            </div>


            <div className='doctor'>
                <div className='doctor2'>
                    <br /><br />
                    <h1>Urology  </h1>
                </div>
                <div className='doctor2'>
                    <p> Dr. Dharamveer Singh, Mch </p>
                    <p>Dr. Ravi Lohani, Mch</p>
                </div>
            </div>


            <div className='doctor'>
                <div className='doctor2'>
                    <br /><br />
                    <h1>Proctology  </h1>
                </div>
                <div className='doctor2'>
                    <p> Dr. Ankush Misra, MS, FIAGES, FALS </p>
                    <p>Dr. KK Agarwal, MS</p>
                </div>
            </div>

            <div className='doctor'>
                <div className='doctor2'>
                    <br /><br />
                    <h1>Plastic Surgery </h1>
                </div>
                <div className='doctor2'>
                    <p> Dr. Vivek Gupta, MCH </p>

                </div>
            </div>


            <div className='doctor'>
                <div className='doctor2'>
                    <br /><br />
                    <h1>Neurosurgery </h1>
                </div>
                <div className='doctor2'>
                    <p>Dr. Apoorv Kumar, MCH</p>

                </div>
            </div>


            <div className='doctor'>
                <div className='doctor2'>
                    <br /><br />
                    <h1>Paediatric Surgery  </h1>
                </div>
                <div className='doctor2'>
                    <p> Dr. JD Ravat, MCH </p>
                    <p>Dr. Sunil Kanaujia MCH</p>
                </div>
            </div>

            <div className='doctor'>
                <div className='doctor2'>
                    <br /><br />
                    <h1>General Medicine  </h1>
                </div>
                <div className='doctor2'>
                    <p> Dr. Md. Amzarul, MD</p>
                    <p>Dr. Vikas Verma, MD</p>
                    <p>Dr. Amit Kumar, DNB</p>
                </div>
            </div>

            <div className='doctor'>
                <div className='doctor2'>
                    <br /><br />
                    <h1>Paediatrics  </h1>
                </div>
                <div className='doctor2'>
                    <p>Dr. Monika Chopra, MD</p>
                    <p>Dr. Manish, MD</p>
                </div>
            </div>

            <div className='doctor'>
                <div className='doctor2'>
                    <br /><br />
                    <h1>Gastromedicine  </h1>
                </div>
                <div className='doctor2'>
                    <p>Dr. Prashant Katiyar, DM</p>
                    <p>Dr. Amit Prakash, DM</p>
                </div>
            </div>

            <div className='doctor'>
                <div className='doctor2'>
                    <br /><br />
                    <h1>Orthopaedics  </h1>
                </div>
                <div className='doctor2'>
                    <p>Dr. Alpesh Shukla</p>
                    <p>Dr. Sulabh Mehrotra</p>
                </div>
            </div>


            <div className='doctor'>
                <div className='doctor2'>
                    <br /><br />
                    <h1>ENT  </h1>
                </div>
                <div className='doctor2'>
                    <p>Dr. Nitin Joshi</p>
                    <p>Dr. Sulabh Mehrotra</p>
                </div>
            </div>



        </>
    )
}

export default Ankus