import React,{useEffect} from 'react'


import Footer from '../Components/Footer';
import Depart from '../Components/Depart';

const Treat = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>
      <Depart />
      <Footer />

    </>
  )
}

export default Treat
