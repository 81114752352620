import React , {useEffect}from 'react';
import slide1 from '../Img/banner.jpg';
import slide2 from '../Img/new.jpg';
import slide3 from '../Img/ayus.jpg';

import Ambu from '../Img/ambu.png';
import Icu from '../Img/front2.jpg';
import nicu from '../Img/nicu.jpg';

import Footer from '../Components/Footer';

const Gallery = () => {
      useEffect(() => {
              window.scrollTo(0, 0);
          }, []);
    return (
        <>
            <div className='gallery'>

                <div className='gallery2'>
                    <img src={slide1} className='img-fluid' alt='Slide 1' />
                </div>

                <div className='gallery2'>
                    <img src={slide2} className='img-fluid' alt='Slide 2' />
                </div>

                <div className='gallery2'>
                    <img src={slide3} className='img-fluid' alt='Slide 3' />
                </div>

                <div className='gallery2'>
                    <img src={Ambu} className='img-fluid' alt='Ambulance' />
                </div>

                <div className='gallery2'>
                    <img src={Icu} className='img-fluid' alt='ICU' />
                </div>

                <div className='gallery2'>
                    <img src={nicu} className='img-fluid' alt='NICU' />
                </div>

            </div>

            <Footer />
        </>
    );
}

export default Gallery;
