import React , {useEffect}from 'react'
import Footer from '../Components/Footer'
import Gynae  from '../Img/gynae.jpg'



const Desiese = () => {

      useEffect(() => {
          window.scrollTo(0, 0);
      }, []);
    
    
    return (

        <>
            <br />
            <center><h1>Gynaecology</h1></center>

            <div className='gyne'>

                <div className='gyne2'>
                    <p><b>Routine Check-ups:</b> Gynecologists provide routine examinations, often referred to as annual exams or well-woman exams, to monitor and maintain reproductive health.</p>
                    <p><b>Pregnancy and Prenatal Care:</b> Gynecologists play a crucial role in providing care during pregnancy, from conception through delivery. They monitor the health of both the mother and the developing fetus.</p>
                    <p><b>Family Planning:</b> Gynecologists offer advice and services related to family planning, contraception, and fertility issues.</p>
                    <p><b>Menopause Management:</b> Gynecologists help women manage the physical and emotional changes associated with menopause, including hormone replacement therapy when needed.</p>
                    <p><b>Gynecological Surgeries:</b> Gynecologists may perform surgical procedures such as hysterectomy, tubal ligation, and others for medical or reproductive health reasons.</p>

                </div>


                <div className='gyne2'>
                    <img src={Gynae}/>
                </div>

            </div>

            <Footer />

        </>
    )
}

export default Desiese

